import { defineComponent, reactive, onMounted, getCurrentInstance, ComponentInternalInstance } from 'vue'
import TuiPopup from '@/components/select/index'
import { SET_USER } from '@/utils/constant'
import { upload, accountUpdate } from '@/api/index'
import { useRouter } from 'vue-router'

import './index.less'


export default defineComponent({
    name: 'personInfo',
    setup() {
        const router = useRouter()
        const { appContext } = getCurrentInstance() as ComponentInternalInstance
        const proxy = appContext.config.globalProperties
        const state: any = reactive({
            isNext: true,
            avatar: 'https://img.fanwoon.com/avatar.png',
            data: {
                gender: 1,//性别
                jobGrade: '1', //我是
                name: '',//姓名
                otherjob: '',//其他职位
            },
            user: {},
            showJobGrade: false,
            bool: false, // 是否显示其他职位
         })

         onMounted(() => {
            _init()
         })


          //快速复制职位操作
        const jobGrade = {
            data: [
                { name: '先生', id: 1, selected: true },
                { name: '女士', id: 0, selected: false },
            ], // 数据
            selected: { name: '', id: 1}, //选中的值
            open () {
                state.showJobGrade = true 
            },
            close () {
                state.showJobGrade = false 
            }
        }

         const _init = () => {
            // debugger
             let res: any = localStorage.getItem(SET_USER)
             if (!res)return 
             res = JSON.parse(res)
             state.user = res.user
             state.data.jobGrade = res.user.jobGrade
             state.data.name = res.user.name
             const gender = res.user.gender
             jobGrade.data.forEach((item: any) => {
                 if(item.id === gender) {
                     item.checked = true
                     jobGrade.selected = item
                     console.log(jobGrade, '33333')
                 } else {
                     item.checked = false
                 }
             })

             if (res.user.imgUrl) {
                state.avatar = res.user.imgUrl
             }

             if ( Number(res.user.jobGrade ) === 4 ) {
                state.bool = true
                state.data.otherjob = res.user.otherjob
             }
            
             

         }

        //验证姓名
        const validatorLeng20 = (value:string): string | boolean => {
            if (!value)return "姓名不能为空！"
            if (value.length > 20) return "最多不超过20个字符!"
            return true
         }

         const onJobGrade = (val:number) => {
            Number(val) === 4 ? state.bool = true : state.bool = false
        }

        //验证其他职位
        const validatorOtherJob = (value: string): string | boolean => {
            if (!value) return '必须要输入具体的身份!'
            if (value.length > 20) return '最多不超过20个字符!'
            return true
        }

       

        const onCallBack = (val: any) => {
            console.log(val)
            jobGrade.selected = val
        }

        const onAfterRead = async (e: any) => {
            const file = e.file
            const formData = new FormData(); // 创建form对象
            formData.append('file', file)
            const res: any = await upload(formData)
            state.avatar = res.data
        }

        const onEdite = async () => {
            let res: any = localStorage.getItem(SET_USER)
            res = JSON.parse(res)
            if (!state.data.name) {
                return proxy.$toast('请输入姓名!');
            }
            if (!state.data.jobGrade) {
                return proxy.$toast('请选择职位类型!');
            }

            /* const job = new Map([
                [1, '店长'],
                [2, '区域/商圈经理'],
                [3, '招聘人员'],
                [4, '其他']
            ])  */

            const status = Number(state.data.jobGrade)
            if (status === 4 && !state.data.otherjob) {
                return proxy.$toast('请输入职位!');
            }
            if (!state.isNext) return 

            const data: any = {
                name: state.data.name,
                gender: jobGrade.selected.id,
                id: res.user.id,
                code: 99999,
                jobGrade: state.data.jobGrade,//job.get(status), //
                imgUrl: state.avatar
            }
            if (status === 4) {
                data['otherjob'] = state.data.otherjob
            }
            state.isNext = false
            const resdata: any = await accountUpdate(data)
            if (resdata.code === 0) {
                state.isNext = true
                proxy.$toast('信息修改成功!');
                res.user.name = state.data.name 
                res.user.gender = jobGrade.selected.id
                res.user.jobGrade = state.data.jobGrade
                res.user.imgUrl = state.avatar
                console.log(res, '最后处理了什么')
                localStorage.setItem(SET_USER, JSON.stringify(res))
            }
            console.log(data, '处理的数据')
        }

        const onCancel = () => {
            router.push({path: '/personal'})
        }

        return () => (
            <div class="info-page">
                <div class="header-box flex-box justify-content-between plr35">
                    <div style={{fontSize: '14Px'}}>编辑个人信息</div>
                    <img class="logo" src="https://img.fanwoon.com/new_logo.png" />
                </div>
                <ul class="form-list">
                    <li class="flex-box align-items-center">
                        <div><img class="avatar-box" src={ state.avatar } /></div>
                        <div class="update-title">
                           <van-uploader after-read={ onAfterRead } result-type="file">
                                <span>修改头像</span>
                            </van-uploader> 
                        </div>
                    </li>
                    <li>
                        <div class="form-list-item">
                                <div><label class="star">*</label>姓名</div>
                                <div class="flex-box align-items-center">
                                    <div class="left-box">
                                        <van-field 
                                            name="name" 
                                            v-model={state.data.name} 
                                            class="input-box name-box" 
                                            placeholder="请输入真实姓名"
                                            rules={[{ validator: validatorLeng20 }]}
                                            />
                                    </div>
                                    <div class="right-box">
                                        <div onClick={() => jobGrade.open() } class="block-input flex-box justify-content-between align-items-center gutter">
                                            <div>{ JSON.stringify(jobGrade.selected) == '{}' ? '请选择' :  jobGrade.selected.name }</div>
                                            <div><van-icon name="arrow-down" /></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                    </li>
                    <li>
                        <div class="form-list-item mt28">
                                <div><label class="star">*</label>我是</div>
                                <div>
                                    <van-radio-group v-model={state.data.jobGrade} direction="horizontal" onChange={onJobGrade}>
                                        <van-radio name="店长" checked-color="#FE4A49">店长</van-radio>
                                        <van-radio name="区域/商圈经理" checked-color="#FE4A49">区域/商圈经理</van-radio>
                                        <van-radio name="招聘人员" checked-color="#FE4A49">招聘人员</van-radio>
                                        <van-radio name="其他" checked-color="#FE4A49">其他</van-radio>
                                    </van-radio-group>
                                </div>
                                { 
                                    !state.bool ? '' : (
                                        <div>
                                            <van-field 
                                                name="otherjob"  
                                                v-model={state.data.otherjob} 
                                                class="input-box" 
                                                placeholder="请输入您的职位" 
                                                rules={[{ validator: validatorOtherJob }]}
                                                />
                                        </div>
                                    )
                                    
                                }
                                
                            </div>
                    </li>
                    <li>
                        <span>门店：</span><span>{ state.user.shortName }</span>
                    </li>
                    <li>
                        <span>手机号：</span><span>{ state.user.phone }</span>
                    </li>
                    <li>
                        <span>角色：</span><span>{state.user.system ? '管理员' : '普通员工' }</span>
                    </li>
                </ul>
                <div class="footer-box-fixed">
                    <button class="cancel-btn" onClick={ onCancel }>取消</button>
                    <button class="sub-btn" onClick={ onEdite }>提交</button>
                </div>
                {
                    !state.showJobGrade? '' : 
                     (
                        <TuiPopup
                        show={ state.showJobGrade} 
                        data={ jobGrade.data } 
                        selected={ jobGrade.selected } 
                        column= {1}
                        { ...{  
                            onClosePopup: jobGrade.close,
                            onCallBack: onCallBack 
                        } }
                        /> 
                     )
                }
            </div>
        )
    }
})